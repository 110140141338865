import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    //Home Page
    topline: "BUILD TRUST, ACHIEVE MORE.",
    title: "The only OKR platform that understands your team.",
    description:
      "Kanam helps SMEs align their vision, empower employees, and track progress while respecting individual preferences and building trust every step of the way",
    startTrial: "Start free trial",
    scheduleDemo: "Schedule a demo",
    trialNote: "30-day free trial, no card required",
    product: "Product",
    pricing: "Pricing",
    aboutUs: "About Us",
    resources: "Resources",
    okrMaturityAssessment: "OKR Maturity Assessment",
    okrMaturityTooltip:
      "Discover your OKR maturity level (Explorer, Builder, or Advanced) and get a free action plan.",
    login: "Login",
    profile: "Profile",
    changePassword: "Change Password",
    logout: "Logout",
    tryForFree: "Try for free",
    signUpForFree: "Sign up for Free",
    askForDemo: "Ask for a Demo",
    claimExclusiveOffer: "Claim Your Exclusive Offer",
    alignAchieveExcel:
      "Align, achieve, excel: revolutionize performance with strategic OKR integration",
    bridgeGap:
      "Bridge the gap between aspiration and action: our OKR Platform translates ambitious goals into measurable<br></br> successes, mitigating fears and fueling growth through a proven, empathetic approach.",
    unifiedGoals: "Unified Goals & Vision Alignment",
    unifiedGoalsDesc:
      "Synchronize every level of your organization with our seamless integration features, ensuring unified direction and enhanced teamwork. Drive towards your vision with everyone in harmony.",
    achievementInsights: "Instant Achievement Insights",
    achievementInsightsDesc:
      "Access immediate clarity on progress with our dynamic dashboards and analytics, enabling swift, data-driven decisions to keep your goals within reach.",
    flexibleObjectives: "Flexible Objective Crafting",
    flexibleObjectivesDesc:
      "Design your success path with our adaptable goal-setting tools. Set stretch goals or precise targets to align with your strategic ambitions, empowering your team to achieve greatness.",
    performanceRecognition: "Inspiring Performance & Recognition",
    performanceRecognitionDesc:
      "Elevate the goal-reaching experience to a journey of fulfillment and recognition. Cultivate a motivated and engaged workplace that celebrates every milestone.",
    harmonizeGoals:
      "Harmonize Goals, Accelerate Success with Unified OKR Engagement",
    navigate:
      "Navigate the complexities of goal alignment with ease and confidence. Our platform not only simplifies the OKR process but transforms it into a source of motivation and clarity, ensuring every team member moves together towards shared victories.",
    exclusiveLaunchOffer:
      "Exclusive Launch Offer: Early Bird Benefits for Life",
    joinUsLaunch:
      "Join us during our launch and secure your special rate forever, plus gain access to all future enhancements at no extra cost.",
    thankYou:
      "It's our way of saying thank you for being part of our journey from the start.",
    faqTitle: "Frequently Asked Questions",
    faq1: "What is OKR Management Software?",
    faq1Desc:
      "Our OKR Management Software is a tool designed to help organizations set, track, and achieve their objectives and key results. It simplifies goal alignment across teams, enhancing transparency and accountability.",
    faq2: "How does your software differ from other goal-setting tools?",
    faq2Desc:
      "Our software uniquely incorporates neuroscience principles like reward systems, gamification, and instant feedback to enhance user engagement.",
    faq3: "Who should use OKR Management Software?",
    faq3Desc:
      "Any organization, from small startups to large enterprises, looking to improve goal alignment, enhance team productivity, and achieve ambitious growth targets.",
    faq4: "What support and resources are available to new users?",
    faq4Desc:
      "All users receive personalized support from a dedicated Account Manager and Customer Success Manager.",
    faq5: "What is included in the exclusive launch offer?",
    faq5Desc:
      "It guarantees you lifetime early bird pricing and access to all future upgrades at no additional cost.",
    getStartedTitle: "Get started with Kanam",
    getStartedDescription:
      "For a modest investment, you're leveraging less than 0.01% of the average annual employee cost to significantly enhance productivity and goal alignment across your team.",
    transformGoals: "Ready to Transform Your Goals into Realities?",
    journeyToExcellence: "Begin Your Journey to Excellence Today",
    stillHaveQuestion: "Still have a question?",
    contactUs: "Contact us!",
    happyToHelp: "We are happy to help.",

    //About Page

    //Pricing Page

    pricingTitle: "Our Pricing",
    pricingDescription:
      "Pay securely online and manage the booking via desktop or via the mobile app.",
    recommended: "Recommended",
    selectPlan: "Select Plan",
    currentPlan: "Current plan",
    upgradePlan: "Upgrade Plan",
    buyPlan: "Buy a plan",
    trial: "Trial",
    freeTrial: "30 day free trial",
    foreverFree: "Forever",
    noCreditCard: "(No Credit Card required)",
    features: "Features",
    cancelPlan: "Cancel Plan",
    cancelConfirmation: "Are you sure you want to cancel your plan?",
    confirmYes: "Yes",
    perMonth: "/ per month",

    //Footer

    company: "Company",
    product: "Product",
    pricing: "Pricing",
    support: "Support",
    faq: "FAQ",
    privacyPolicy: "Privacy Policy",
    termsOfService: "Terms of Services",
    subscribeNewsletter: "Subscribe Our Newsletter",
    subscribeDescription: "Sign up and receive the latest tips via email.",
    enterYourEmail: "Enter Your Mail",
    subscribeNow: "Subscribe Now",
    copyright: "©2025 Kanam. All right reserved",

    //Login Page

    loginTitle: "Log in to your account",
    emailLabel: "Email*",
    emailPlaceholder: "Email address",
    passwordLabel: "Password*",
    passwordPlaceholder: "Password",
    forgotPassword: "Forgot Password?",
    signInButton: "Sign in",
    noAccount: "Don’t have an account?",
    startFreeTrial: "Start with free trial",

    //Dashboard

    dashboard: "Dashboard",
    hi: "Hi",
    analyticsSession: "Analytics of current session :",
    overview: "Overview",
    numberObjectives: "Number of Objectives",
    objectives: "Objectives",
    timeSpent: "Time Spent",
    daysLeft: "days left",
    resultSummary: "Key Results Summary",
    currentFocus: "My Current Focus",
    filter: "Filters",
    confidence: "Confidence",
    owner: "Owner",
    contributor: "Contributors",
    Action: "Action",
    progress: "OKR/Progress",

    session: "Session",
    objectiveProgress: "Objective Progress",
    objective: "Objective",

    //HUD

    trialPeriod: "Trial Period: Forever",
    upgradePlan: "UPGRADE PLAN",
    onTrack: "ON TRACK",
    offTrack: "OFF TRACK",
    atRisk: "AT RISK",
    okrs: "OKRs",
    people: "People",
    allSessions: "All Sessions",
    settings: "Settings",
    supportCenter: "Support Center",
    notifications: "Notifications",
    edit: "Edit",
    addKR: "Add Key Result",
    addChild: "Add Child Objective",
    delete: "Delete",
    recentSessions: "Recent Sessions",
    myTeams: "My Teams",
    accountAndDomain: "Account and Domain",
    planAndBilling: "Plan and Billing",

    //Employee Details Page

    createdOn: "Created on",
    email: "Email",
    manager: "Manager",
    team: "Team",
    whatILoveDoing: "What I love doing effortlessly",
    howToCommunicate: "How to communicate with me",
    whatMattersMost: "What matters most to me at work",
    whatCanFrustrate: "What can frustrate or block me",
    somethingAboutMe: "Something you should know about me",
    trustBattery: "Trust Battery (How Trust Works for Me)",
    trustLevel: "My default trust level",
    gainTrust: "How to gain my trust",
    loseTrust: "How to lose my trust",
    edit: "Edit",
    deactivateEmployee: "Deactivate Employee",
    activateEmployee: "Activate Employee",
    deleteEmployee: "Delete Employee",
    confirmDelete: "Do you really want to delete this employee?",
    confirmStatusChange:
      "Do you really want to change the status of this employee?",
    uploadImage: "Upload Image",
    cancel: "Cancel",
    update: "Update",
    startTrust: "Trust must be earned from scratch.",
    trustWithProof: "I trust people, but I need to see proof.",
    trustUntilProvenOtherwise: "I trust fully until proven otherwise.",
    likesDislikes: "Likes and Dislikes",
    sustainTrust:
      "How do you build and sustain trust in your team? Share your style so others can collaborate better with you",
    refillBattery: "How to refill my battery",
    drainBattery: "How to drain my battery",
    employee: "employee",
    teams: "Teams",
    teamsOrg: "Teams Org",
    employees: "Employees",
    withoutTeam: "Without Team",
    noTeam: "No Team",

    //OKR Page

    alignment: "Alignment",
    lastUpdated: "Last Updated",
    createOKR: "Create OKR",
    createAiOKR: "Create AI OKR",
    captureGoal: "Capture your goal and set the path for success!",
    createNew: "Create a new",
    update: "Update",
    okrTitle: "Title",
    whyNow: "Why now?",
    connectParent: "Connect To Parent OKR",
    searchOKR: "Search OKRs, Key results and Objectives",
    create: "Create",
    okrDescription: "Description",
    keyResult: "Key Result",

    //OKR Pop-up Page

    overallProgress: "Overall Progress",
    shouldMoveto: "Should move to",
    currentProgress: "current progress at",
    shareVia: "Share via",
    checkin: "Check-in",
    keepAligned:
      "Keep everyone aligned! Quickly notify your team about progress or updates.",
    targetDate: "Target Date",
    noTargetDate: "No Target Completion Date",
    hardDeadline: "Hard Deadline",
    hardSoft: "Hard & Soft Deadline",
    softDeadline: "Soft Deadline",
    addkeyResult: "Add Key Result",
    unit: "Unit",
    numbers: "Number",
    defineOutcome:
      "Define a clear and measurable outcome to drive progress toward your objective.",
    createKR: "Create Key Result",
    items: "Items",
    score: "Score",
    keyResults: "Key Results",
    addChild: "Add Child Objective",
    add: "Add",

    // Check-In

    checkProgress: "Check-in on your progress",
    doingGreat: "You're doing great! Share your updates on this Key Result.",
    currentValue: "Current Value",
    newValue: "New Value",
    dateUpdate: "Date of Update",
    confidenceLVL: "Confidence Level",
    victory: "Victory! You've nailed a Key Result. Here's some confetti! 🏆🚀",
    show: "Show",
    of: "of",
    records: "records",

    //Session Page

    createSession: "Create Session",
    session: "session",
    asterisk: "All required fields are marked with an asterisk",
    name: "Name",
    sessionName: "Give your session a name",
    timeframe: "Timeframe",
    startDate: "Start Date",
    endDate: "End Date",
    cadence: "Cadence",
    weekly: "Weekly",
    twoWeeks: "Every two weeks",
    month: "Monthly",
    every: "Every",
    on: "on",
    firstDay: "On the first day of the month",
    lastDay: "On the last day of the month",
    specificDate: "On a specific date",
    color: "Color",

    //Billing Page

    billingFor: "Billing for",
    nextInvoiceDate: "Next Invoice Issue Date",
    myInvoices: "My Invoices",
    totalPaidAmount: "Total Paid Amount:",
    managePlan: "Manage Plan",
    myTransactions: "My Transactions",
    monthlyCost: "Monthly Cost",
    transactionDate: "Transaction Date",
    seat: "Seat",
    seats: "Seats",
    paidAmount: "Paid Amount",
    use: "use",
    addSeat: "Add Seats",
    addSeatsPlan: "Add Seats to your plan",

    //Account Domain

    nameofOrg: "Organization Profile",
    edit: "Edit Profile",
    cancel: "Cancel",
    save: "Save Changes",
    companyName: "Company Name",
    companyLogo: "Company Logo",
    annualRevenue: "Annual Revenue",
    companyUrl: "Website URL",
    linkedinUrl: "LinkedIn URL",
    industry: "Industry",
    employeeCount: "Number of Employees",
    foundedYear: "Founded Year",
    departments: "Departments",
    keyMetrics: "Key Metrics",
    mission: "Mission",
    vision: "Vision",
    values: "Values",
    productsServices: "Products & Services",
    strategicPriorities: "Strategic Priorities",
    createdAt: "Created At",
    updatedAt: "Last Updated",
    clickToUploadImage: "Click to upload a new company logo",
    basicInfo: "Basic Information",
    contactInfo: "Contact Information",
    companyDetails: "Company Details",
    businessInfo: "Business Information",
    timestamps: "Timestamps",
    noLinkedWorkspace: "No linked workspace",

    //Team Details

    deactivate: "Deactivate",
    activate: "Activate",
    overview: "Overview",
    ourIdentity: "Our Identity",
    teamName: "Team Name",
    teamManager: "Team Manager",
    teamMembers: "Team Members",
    dependencies: "Dependencies on other teams",
    dependentTeams: "Teams Dependent on Us",
    identifiedDependencies: "Identified as a Dependency by Other Teams",
    parentTeams: "Parent Team",
    teamColor: "Team Color",
    nameDesc:
      "Enter a unique name to identify this team across the organization.",
    managerDesc:
      "Specify the primary point of contact responsible for leading this team.",
    memberDesc:
      "Add all individuals who are part of this team. Their roles and responsibilities will align with the team’s objectives.",
    depenDesc:
      "List the teams this group depends on to achieve its objectives. Dependencies indicate areas where collaboration or resources are needed.",
    depenteamDesc:
      "See which teams have independently marked this team as a dependency. Use this for cross-functional alignment and clarity on support needs.",
    identifiedDesc:
      "See which teams have independently marked this team as a dependency. Use this for cross-functional alignment and clarity on support needs.",
    parentDesc:
      "Select the parent team if this team is part of a larger organizational unit.",
    colorDesc:
      "Choose a color to represent this team visually across the platform, making it easily distinguishable from others.",
    status: "Status",
    active: "Active",
    inactive: "Inactive",
    noMember: "No members",
    teamMission: "Team Mission",
    teamMissionDescription:
      "Define the team’s core purpose and the unique impact it strives to make within the organization.",
    teamVision: "Team Vision",
    teamVisionDescription:
      "Describe the team’s long-term aspirations and the future it aims to create.",
    values: "Values",
    valuesDescription:
      "List the guiding principles that shape the team’s culture and decision-making. Click 'Add' to include each value.",
    addValue: "Add Value",
    close: "close",
    save: "Save",
    titlename: "Title",
    descriptionname: "Description",
    deleteTeam: "Delete Team",
    value: "Value",

    //Team Listing

    hasOne: "Has only 1 member, led by",
    ledBy: "led by",
    noMember: "No members",
    image: "Image",
    teamName: "Team name",
    createdAt: "Created at",
    mission: "Mission",
    members: "Members",
    action: "Action",
    createTeam: "Create Team",
    change: "Change",
    upload: "Upload",
    parentTeam: "Parent Team",
    teamImage: "Team Image",
    removeImage: "Remove Image",
    addChildTeam: "Add Child Team",
    view: "View",

    //Employee Listing

    all: "All",
    withTeam: "With Team",
    witoutTeam: "Without Team",
    usedSeats: "Used Seats",
    totalSeats: "Total Seats",
    addEmployee: "Add employee",
    addSeats: "Add Seats",
    createEmployee: "Create Employee",
    updateEmployee: "Update Employee",

    //Profile Page

    companyName: "Company Name",
    mobile: "Mobile No",
    role: "Role",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmPassword: "Confirm New Password",
    minLength: "Password must contain at least 8 characters",
    notMatched: "The new passwords do not match",

    Session: "Session",

    // New translations
    aboutKanam: "About Kanam",
    unlockingExcellence: "Unlocking excellence with OKRs",
    atKanam:
      "At Kanam, we believe that true performance isn’t just about setting goals—it’s about creating a culture of excellence where every team member is aligned, engaged, and empowered to deliver results.",
    moreThanTool: "More than a tool—A partner in your success",
    powerfulOKRPlatform: "Powerful OKR Platform",
    aiAssistedGoalSetting:
      "AI-assisted goal-setting, real-time tracking, and smart recommendations to keep your teams aligned and focused.",
    expertGuidance: "Expert Guidance",
    ourTeamHelps:
      "Our team helps you roll out OKRs effectively, ensuring the framework is embedded into your company’s DNA, not just into a system.",
    fastTrackToResults: "Fast-Track to Results",
    weWorkAlongside:
      "We work alongside you to accelerate adoption, minimize friction, and drive measurable improvements in execution.",
    roiOfOKRs: "The ROI of OKRs—A no-brainer investment",
    higherExecutionSpeed: "Higher Execution Speed",
    even20Improvement:
      "Even a 20% improvement in efficiency means delivering more with the same resources.",

    clearerpriorities: "Clearer Priorities",
    clearerdetail:
      "Teams spend less time on distractions and more time on what truly moves the needle.",
    engagement: "Better Engagement & Accountability",
    engagementdetail:
      "Employees feel more connected to their work, leading to stronger performance.",
    CalculateROI: "Calculate your ROI with Kanam",
    Company: "Your Company",
    TeamSize: "Team Size (Number of Employees)",
    SalaryPer: "Annual Average Salary Per Employee ($)",
    Project: "Your OKR Project",
    ProductivityIncrease: "Expected Productivity Increase",
    IndustryInsight:
      "💡 Industry Insight: According to a McKinsey study, organizations that effectively implement OKRs experience a 30% increase in employee engagement and productivity.",
    Payroll: "Payroll Cost ($)",
    employeesalaries:
      "The cost of employee salaries, providing a baseline for productivity impact calculations.",
    Investment: "Investment ($)",
    InvestmentDetail:
      "The amount invested in the initiative, including training, tools, and resources.",
    CapacityCreated: "Capacity Created",
    CapacityDetail:
      "Represents the additional workforce capacity gained through productivity improvements—like adding X full-time employees without extra cost.",
    ProjectedROI: "Projected ROI",
    ProjectedDetail:
      "The estimated financial return generated from increased efficiency and alignment, expressed in dollars.",
    MinimumROI: "Minimum ROI (%)",
    MinimumDetail:
      "The lowest expected return on investment, ensuring even conservative estimates show a positive impact.",
    Calculated: "💡 ROI Calculated!",
    footnote: "All data are presented on a monthly basis.",
    ExpertTalk: "Talk to an Expert",
    transformorganization: "Ready to transform your organization?",
    Iflooking: "If you’re looking to",
    align:
      "align your teams, increase execution speed, and foster a results-driven culture",
    Kanampartner: ", Kanam is the partner you need.",

    values: "Our values",

    title1: "Be Humble",
    frontText1:
      "Everyone has something to learn. Seek first to understand before seeking to be understood.",
    backText1: "Stay humble, stay curious.",

    title2: "Improve with Intention",
    frontText2:
      "Strive for continuous improvement in every action you take. Keep working on yourself.",
    backText2: "Growth is a daily choice.",

    title3: "Find Leverage",
    frontText3:
      "Identify the action that will create the most value in the short or long term. Be intentional with your time.",
    backText3: "Small actions, big impact.",

    title4: "Build Strong Relationships",
    frontText4:
      "Nurture trust with colleagues, partners, and clients. This also means giving and receiving honest feedback.",
    backText4: "Trust is built with consistency.",

    title5: "Be Obsessed with Your Field",
    frontText5:
      "To become the best, develop a deep passion for your domain, learn from the best, and improve daily.",
    backText5: "Passion drives mastery.",

    title6: "Think Big. Start Small. Start Now.",
    frontText6:
      "Creating meaningful value requires taking risks and managing them effectively.",
    backText6: "Start before you're ready.",

    // Settings Page

    accountName: "Account Name",
    nameofOrg: "The name of your organisation",
    company: "Company",
    logout: "Log Out",
    explainBriefly:
      "Explain briefly why it is important to focus on this objective now",

    // Onboarding page
    onboarding: "Onboarding",
    company_logo: "Company Logo",
    company_image: "Company Image",
    company_image_required: "Company Image is required",
    click_to_upload_image: "Click to upload image",
    company_name: "Company Name",
    company_name_required: "Company Name is required",
    company_url: "Company URL",
    founded_year: "Founded Year",
    industry: "Industry",
    about: "About",
    vision: "Vision",

    employee_count: "Employee Count",
    annual_revenue: "Annual Revenue",
    departments: "Departments",
    strategic_priorities: "Strategic Priorities",
    products_services: "Products & Services",
    key_metrics: "Key Metrics",
    linkedin_url: "LinkedIn URL",
    next: "Next",
    skip: "Skip",
    profile_picture: "Profile Picture",

    upload_image: "Upload Image",
    first_name: "First Name",
    first_name_required: "First Name is required",
    last_name: "Last Name",
    last_name_required: "Last Name is required",

    email_required: "Email is required",
    mobile_no: "Mobile No",
    enter_phone_number: "Enter phone number",
    what_i_love_doing: "What I Love Doing",
    how_to_communicate: "How to Communicate",
    what_matters_most: "What Matters Most",
    what_can_frustrate: "What Can Frustrate",
    something_about_me: "Something About Me",
    default_trust_level: "Default Trust Level",
    how_to_gain_trust: "How to Gain Trust",
    how_to_lose_trust: "How to Lose Trust",
    previous: "Previous",
    complete_onboarding: "Complete Onboarding",
  },
};

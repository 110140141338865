import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import PageLayout from "../../components/global/PageLayout";

import "./privacy.scss";
import translate from "../../i18n/translate";

export default function Privacy() {
  const [detail, setDetail] = useState();

  const getDetail = () => {
    loader(true);
    const language = navigator.language || "en";
    ApiClient.get("content", { slug: "privacy-policies", lang: language }).then(
      (res) => {
        if (res.success) {
          setDetail(res.data);
        }
        loader(false);
      }
    );
  };
  useEffect(() => {
    getDetail();
  }, []);

  {
    /* <h1 className="mb-3">{detail?.title}</h1>
this goes into the pagelayout to make it dynamic based off bdd
          <div dangerouslySetInnerHTML={{ __html: detail?.description }}></div> */
  }

  return (
    <>
      <PageLayout>
        <div className="privacy-page-container">
          <h1 className="privacy-title">Privacy Policy</h1>
          <p className="privacy-last-updated">Last updated: 2025-04-01</p>
          <a
            href="https://kanam.io/privacy"
            target="_blank"
            rel="noopener noreferrer"
            className="privacy-link"
          >
            https://kanam.io/privacy
          </a>

          <section className="privacy-section">
            <h2 className="privacy-heading">1. Data Controller</h2>
            <p className="privacy-text">
              <strong>Name:</strong> Darewell
              <br />
              <strong>Address:</strong> 8 allée de la chapelle, 74940 Annecy Le
              Vieux, France
              <br />
              <strong>SIRET:</strong> 910.432.152
              <br />
              <strong>DPO:</strong>{" "}
              <a href="mailto:dpo@kanam.io" className="privacy-email">
                Fouad Zein – dpo@kanam.io
              </a>
            </p>
          </section>

          <section className="privacy-section">
            <h2 className="privacy-heading">2. Data We Collect</h2>
            <table className="privacy-table">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Data Types</th>
                  <th>Purpose</th>
                  <th>Legal Basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Account Data</td>
                  <td>Email, name, company name, hashed password</td>
                  <td>User account creation, authentication</td>
                  <td>Contract (Art. 6(1)(b))</td>
                </tr>
                <tr>
                  <td>Behavioral Profiles</td>
                  <td>Default trust level, communication preferences</td>
                  <td>Team collaboration & trust-building</td>
                  <td>Legitimate Interest (Art. 6(1)(f))</td>
                </tr>
                <tr>
                  <td>OKR Data</td>
                  <td>OKR titles, confidence levels, contributors</td>
                  <td>OKR tracking & team alignment</td>
                  <td>Legitimate Interest (Art. 6(1)(f))</td>
                </tr>
                <tr>
                  <td>Payment Data</td>
                  <td>Transaction records, Stripe charge IDs</td>
                  <td>Payment processing & invoicing</td>
                  <td>Legal Obligation (Art. 6(1)(c))</td>
                </tr>
                <tr>
                  <td>AI Features</td>
                  <td>Anonymized company sector, industry, size</td>
                  <td>OKR recommendations & insights</td>
                  <td>Legitimate Interest (Art. 6(1)(f))</td>
                </tr>
              </tbody>
            </table>
          </section>

          <section className="privacy-section">
            <h2 className="privacy-heading">3. How We Use Your Data</h2>
            <ul className="privacy-list">
              <li>Manage user accounts and teams.</li>
              <li>Track OKRs and collaboration metrics.</li>
              <li>Process payments (Stripe).</li>
              <li>
                Generate OKR recommendations using anonymized company data.
              </li>
              <li>
                AI-generated content (e.g., OKR suggestions) is clearly labeled
                as "AI-generated".
              </li>
            </ul>
          </section>

          <section className="privacy-section">
            <h2 className="privacy-heading">4. Data Retention</h2>
            <table className="privacy-table">
              <thead>
                <tr>
                  <th>Data Type</th>
                  <th>Retention Period</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Account Data</td>
                  <td>2 years after account closure</td>
                </tr>
                <tr>
                  <td>Behavioral Profiles</td>
                  <td>2 years after account closure</td>
                </tr>
                <tr>
                  <td>OKR Data</td>
                  <td>2 years after account closure</td>
                </tr>
                <tr>
                  <td>Payment Data</td>
                  <td>10 years (French fiscal law)</td>
                </tr>
              </tbody>
            </table>
          </section>

          <section className="privacy-section">
            <h2 className="privacy-heading">5. Data Sharing & Transfers</h2>
            <table className="privacy-table">
              <thead>
                <tr>
                  <th>Recipient</th>
                  <th>Purpose</th>
                  <th>Location</th>
                  <th>Safeguards</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Company Members</td>
                  <td>Access to shared data</td>
                  <td>Global (user locations)</td>
                  <td>
                    Data stored in EU (Germany) + Role-based access controls
                  </td>
                </tr>
                <tr>
                  <td>Stripe</td>
                  <td>Payment processing</td>
                  <td>US/EU</td>
                  <td>SCCs</td>
                </tr>
                <tr>
                  <td>OpenAI</td>
                  <td>AI features</td>
                  <td>US (via EU)</td>
                  <td>SCCs + Anonymization</td>
                </tr>
              </tbody>
            </table>
          </section>

          <section className="privacy-section">
            <h2 className="privacy-heading">6. Your Rights</h2>
            <ul className="privacy-list">
              <li>Access your data.</li>
              <li>Correct inaccurate data.</li>
              <li>Delete your data (unless legally required to retain).</li>
              <li>Object to processing based on legitimate interests.</li>
              <li>Port your data (e.g., export OKRs).</li>
            </ul>
            <p className="privacy-text">
              To exercise your rights, email us at{" "}
              <a href="mailto:support@kanam.io" className="privacy-email">
                support@kanam.io
              </a>
              . We will respond within 30 days.
            </p>
          </section>
        </div>
      </PageLayout>
    </>
  );
}

import React, { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import loader from "../../methods/loader";
import { useHistory } from "react-router-dom";
import "./style.scss";
import "react-datepicker/dist/react-datepicker.css";
import { CiAlignRight } from "react-icons/ci";
import { MdDataObject } from "react-icons/md";
import ModalOKR from "./ModalOKR";
import EmployeesListing from "../Employees/EmployeesListing";
import { useDispatch, useSelector } from "react-redux";
import AlignmentOKR from "./AlignmentOKR";
import { LuCircleDotDashed } from "react-icons/lu";
import { MdOutlineSignalCellularAlt } from "react-icons/md";
import { sessions } from "../../actions/session";
import Select from "react-select";
import { Menu } from "@headlessui/react";
import { CiFilter } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import ChildModalOKR from "./ChildModalOKR";
import methodModel from "../../methods/methods";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  FaArrowDown,
  FaArrowRight,
  FaRegCalendarAlt,
  FaUserCircle,
} from "react-icons/fa";
import Pagination from "react-pagination-js";
import { I18nProvider, LOCALES } from "../../i18n";
import translate from "../../i18n/translate";

const OKRindex = ({ layout = true, ownerID, sessionId, getcounts }) => {
  const { id } = useParams();
  const [LoadingState, setLoadingState] = useState(false);
  const getsessionId = localStorage.getItem("sessionID");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const sessionss = useSelector((state) => state.session);
  const SessionFilter = sessionss?.data
    ? { label: sessionss?.data, value: id }
    : "";
  const [data, setdata] = useState([]);
  const [filter, setfilter] = useState({
    page: 1,
    count: 10,
    search: "",
    ownerId: "",
    sessionId: sessionId,
  });
  const [alignfilter, setalignfilter] = useState({
    page: 1,
    count: 10,
    search: "",
    ownerId: "",
    sessionId: sessionId,
  });
  const [SessionIdFilter, setSessionIdFilter] = useState(SessionFilter);
  const [childKeyResult, setchildKeyResult] = useState("");
  const [loading, setloading] = useState(false);
  const [total, settotal] = useState(0);
  const [objectiveTotal, setObjectiveTotal] = useState(0);
  const [child, setchild] = useState(0);
  const [detail, setDetail] = useState();
  const navigate = useHistory();
  // Create OKRs
  const [session, setsessions] = useState();
  const [owners, setowners] = useState([]);
  const [multiOptions, setmultiOptions] = useState([]);
  const [form, setform] = useState({
    session: "",
    title: "",
    ownerIds: [],
    contributors: [],
    whyNow: "",
  });
  const [Item, setItem] = useState(null);

  const [keyResult, setkeyResult] = useState({
    title: "",
    unit: "",
    targetNumber: "",
    initialNumber: "",
    description: "",
    ownerIds: [],
    deadline: "no custom deadline",
    softDeadline: "",
    hardDeadline: "",
    session: null,
  });
  const [editor, seteditor] = useState({ keyResult: "", noteKeyResult: "" });
  const [updateKeyResult, setupdateKeyResult] = useState({
    newValue: "",
    confidenceLevel: "high",
    note: "",
    dateOfUpdate: new Date(),
    okrId: "",
    goal: "",
    currentValue: "",
    keyResultId: "",
  });
  const [childObjective, setChildObjective] = useState();
  const [keyResultData, setkeyResultData] = useState();
  const [parentKeyResult, setparentKeyResult] = useState();
  const [dataResources, setResources] = useState([]);
  const [newupdated, setnewupdated] = useState({
    newValue: "",
    confidenceLevel: "high",
    note: "",
    dateOfUpdate: new Date(),
    okrId: "",
    goal: "",
    currentValue: "",
    keyResultId: "",
  });
  const [employeeData, setEmployeeData] = useState([]);

  const handleDataFetched = (data) => {
    setEmployeeData(data);
  };

  useEffect(() => {
    let str = ownerID?.map((itm) => itm.value)?.toString() || "";
    let flt = {
      ownerId: str,
      page: 1,
    };

    if (user?.role == "employee") {
    } else {
      flt.addedBy = user?.id || user?._id || "";
    }
    getData({ ...flt });
    setfilter({ ...filter, ...flt });
    setalignfilter({ ...alignfilter, ...flt });
  }, [id]);

  useEffect(() => {
    getSessions();
    getOwners();
    if (id) {
      getSessionDetail();
    }
    if (sessionss?.data) {
      // localStorage.setItem("sessionNAme", sessionss?.data);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      let el = document.getElementById("pills-objective-tab");
      if (el) {
        el.click();
      }
    }
  }, [id, user]);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const getData = (p = {}, load = true, page = 1) => {
    let filt = {
      ...filter,
      sessionId: id || sessionId || getsessionId,
      keyType: "okr,child_objective",
      sortBy: "createdAt desc",
      page,
      ...p,
    };

    if (user?.role !== "employee") {
      filt = { ...filt, addedBy: user?.id || user?._id };
    } else {
      filt = { ...filt, addedBy: user?.addedBy || user?.addedBy };
    }

    loader(true);
    setLoadingState(true);
    ApiClient.get(`okrs`, filt).then((res) => {
      setLoadingState(false);
      if (res.success) {
        const newData = res?.data;
        setObjectiveTotal(res?.total);
        if (page === 1) {
          setdata(newData);
        } else {
          setdata((prevData) => {
            const combinedData = [...prevData, ...newData];
            const uniqueData = combinedData.filter(
              (item, index, self) =>
                index === self.findIndex((t) => t._id === item._id)
            );
            return uniqueData;
          });
        }
        setChildObjective(res?.data.childObjective);
        if ((detail?.id || detail?._id) && load) {
          setDetail(
            res?.data?.find(
              (item) => item?._id === detail?.id || item?._id === detail?._id
            )
          );
        }
        if (res?.data.length === 0) {
          setHasMore(false);
        }
      }
      loader(false);
    });
  };

  useEffect(() => {
    getData({}, true, 1);
  }, []);

  const fetchMoreData = () => {
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      getData({}, true, nextPage);
      return nextPage;
    });
  };

  const getBrowserLocale = () => {
    const language = navigator.language.toLowerCase();
    if (language.includes("fr")) {
      return LOCALES.FRENCH;
    }
    return LOCALES.ENGLISH;
  };

  const locale = getBrowserLocale();

  const [OKRListing, setOKRListing] = useState([]);

  const getOKRListing = (p = {}, page = 1) => {
    let filt = {
      ...filter,
      sessionId: id || sessionId || getsessionId,
      keyType: "okr",
      sortBy: "createdAt desc",
      page,
      ...p,
    };

    loader(true);
    ApiClient.get(`okrs`, filt).then(async (res) => {
      if (res.success) {
        let data = res?.data.map((itm) => {
          itm.child = [];
          return itm;
        });

        let i = 0;
        for await (let item of data) {
          let child = [];
          if (item.childExist) {
            const res = await ApiClient.get(
              `okrs?okrId=${item?.id || item?._id}`
            );
            if (res.success) {
              child = res.data;
            }
          }
          data[i].child = child;
          i++;
        }

        settotal(res?.total);
        setOKRListing(data);
      }
      loader(false);
    });
  };

  const getSessionDetail = () => {
    ApiClient.get(`session?id=${id || getsessionId}`).then((res) => {
      if (res.data?.id) {
        setform({
          ...form,
          session: { value: res?.data?.id, label: res?.data?.title },
        });
      }
    });
  };

  const getSessions = () => {
    let payload = {
      isDeleted: false,
    };
    if (user?.role == "employee") {
      payload = {
        ...payload,
        addedBy: user?.addedBy,
        sortBy: "start_date asc",
        // active: true
        // sortBy: localStorage.getItem('CreatedAt') == 'true' ? "start_date asc" : "updatedAt desc",
        // active:localStorage.getItem('CreatedAt') == 'true' ? true :''
      };
    } else {
      payload = {
        isDeleted: false,
        addedBy: user?.id || user?._id,
        sortBy: "start_date asc",
        // active: true
      };
    }
    setLoadingState(true);
    ApiClient.get(`sessions`, payload).then((res) => {
      if (res.success) {
        setResources(res?.data);
        setLoadingState(false);
        setsessions(
          res?.data?.map((item) => {
            return { value: item?._id || item?.id, label: item?.title };
          })
        );
        if (
          !localStorage.getItem("ownerId") &&
          !localStorage.getItem("sessionID")
        ) {
          // getData({ sessionId: res?.data[0]?._id || res?.data[0]?.id });
          // getcounts(res?.data[0]?._id || res?.data[0]?.id)
          // localStorage.setItem("sessionID",res?.data[0]?._id || res?.data[0]?.id)
          // localStorage.setItem("sessionNAme",res?.data[0]?.title)
          // localStorage.setItem("endate",res?.data[0]?.end_date)
        }
      }
    });
  };

  const confidenceLevelClass = (item) => {
    let value = `${
      item?.confidenceLevel == "low" || item?.confidenceLevel == ""
        ? "red-color"
        : item?.confidenceLevel == "medium"
        ? "orange-color"
        : item?.confidenceLevel == "high"
        ? "green-color"
        : ""
    }`;

    if (item?.keyType == "child_objective" || item?.keyType == "okr") {
      value = "";
    }

    return value;
  };

  const getKeyResults = (level = 0) => {
    // loader(true);
    ApiClient.get(`okrs`, {
      sessionId: id,
      level: level,
      // okrId: id ? id : detail?.id ? detail?.id : detail?._id,
      addedBy: user?.role == "employee" ? user?.id || user?._id : "",
    }).then((res) => {
      if (res.success) {
        setkeyResultData(res?.data);
        setparentKeyResult(
          res?.data?.map((item) => {
            return { value: item?._id, label: item?.title };
          })
        );
      }
      // loader(false);
    });
  };

  const clearFields = () => {
    setform({ ...form, title: "", ownerIds: "", whyNow: "", contributors: "" });
  };

  const getOwners = () => {
    ApiClient.get(
      `users/list?status=active&addedBy=${user?.id || user?._id}`
    ).then((res) => {
      if (res.success) {
        ApiClient.get(`teams?addedBy=${user?.id || user?._id}`).then((res1) => {
          let arr2 = res1?.data?.map((item) => {
            return {
              value: item?._id || item?.id,
              id: item?._id || item?.id,
              label: item?.fullName || item?.firstName,
            };
          });
          //console.log(res?.data, "res------------");
          let arr = res?.data?.map((item) => {
            return {
              id: item?.id || item?._id,
              value: item?.id || item?._id,
              label: item?.fullName || item?.firstName,
            };
          });
          let Multiarr2 = res1?.data?.map((item) => {
            return {
              id: item?._id || item?.id,
              name: item?.fullName || item?.firstName,
            };
          });
          let Multiarr = res?.data?.map((item) => {
            return {
              id: item?.id || item?._id,
              name: item?.fullName || item?.firstName,
            };
          });

          setmultiOptions([...Multiarr, ...Multiarr2]);
          setowners([...arr, ...arr2]);
        });
      }
    });
  };

  const handleAction = (type, id) => {
    if (type == "Edit") {
      if (!id) return;
      ApiClient.get(`okr?id=${id}`).then((res) => {
        if (res.data?.id) {
          let parentData = res.data.parentData || null;
          setDetail(res?.data);
          setform({
            id: res?.data?.id,
            title: res?.data?.title,
            session: {
              value: res?.data?.sessionTitle?.id,
              label: res?.data?.sessionTitle?.title,
            },
            ownerIds: res?.data?.ownerIds,
            parentId: res.data?.parentId || null,
            parent: parentData
              ? {
                  value: res.data?.parentId,
                  label: (
                    <div className="d-flex">
                      <span className="mr-2 objective-img">
                        {/* <LuCircleDotDashed /> */}
                        {parentData?.keyType == "child_objective" ||
                        parentData?.keyType == "okr" ? (
                          <span className="objective-img ">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10.1 2.18a9.93 9.93 0 0 1 3.8 0"></path>
                              <path d="M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7"></path>
                              <path d="M21.82 10.1a9.93 9.93 0 0 1 0 3.8"></path>
                              <path d="M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69"></path>
                              <path d="M13.9 21.82a9.94 9.94 0 0 1-3.8 0"></path>
                              <path d="M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7"></path>
                              <path d="M2.18 13.9a9.93 9.93 0 0 1 0-3.8"></path>
                              <path d="M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69"></path>
                              <circle cx="12" cy="12" r="1"></circle>
                            </svg>
                          </span>
                        ) : (
                          <span className="key-img">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path fill="none" d="M0 0h24v24H0V0z"></path>
                              <path d="M17 4h3v16h-3V4zM5 14h3v6H5v-6zm6-5h3v11h-3V9z"></path>
                            </svg>
                          </span>
                        )}
                      </span>
                      <span>{parentData.title}</span>
                    </div>
                  ),
                }
              : null,
            keyType: res.data.keyType,
            contributors: res?.data?.contributors,
            whyNow: res?.data?.whyNow,
          });
          setChildObjective(parentData);
          document.getElementById("CreateOKRModalOpen").click();
        }
      });
    } else if (type == "Child Objective") {
      let id1 = id?.id || id?._id;
      if (!id1) return;
      ApiClient.get(`okr?id=${id1}`).then((res) => {
        if (res.data?.id) {
          setDetail(res?.data);
          let parentData = res.data.parentData || null;
          let payload = {
            id: res?.data?.id,
            title: res?.data?.title,
            session: {
              value: res?.data?.sessionTitle?.id,
              label: res?.data?.sessionTitle?.title,
            },
            keyType: res.data.keyType,
            parentId: res.data.parentId || null,
            ownerIds: res?.data?.ownerIds,
            contributors: res?.data?.contributors,
            whyNow: res?.data?.whyNow,
          };
          setform(payload);
          setChildObjective(parentData);
          getKeyResults(res?.data.level - 1);
          document.getElementById("CreateOKRModalOpen").click();
        }
      });
    } else {
      if (window.confirm("Do you really want to delete this okr")) {
        ApiClient.delete(`okr?id=${id}`).then((res) => {
          if (res.success) {
            toast.success(res?.message);
            getData();
            getOKRListing();
            document.getElementById("CloseFullSideScreenModal").click();
          }
        });
      }
    }
  };

  const resetFormState = () => {
    setform({
      id: "",
      title: "",
      session: { value: "", label: "" },
      keyType: "",
      parentId: null,
      ownerIds: [],
      contributors: [],
      whyNow: "",
    });
    setChildObjective(null);
    setupdateKeyResult({
      goal: "",
      currentValue: "",
      parentId: null,
      newValue: "",
      keyResultId: "",
      confidenceLevel: "",
      note: "",
      dateOfUpdate: new Date(),
      unit: "",
    });
  };

  const handleUpdateKeyResultModal = (item) => {
    resetFormState(); // Reset the form state before setting new values

    setupdateKeyResult({
      ...updateKeyResult,
      goal: item?.targetNumber,
      currentValue: item?.initialNumber,
      parentId: item.parentId || null,
      newValue: item?.newValue || item?.initialNumber,
      keyResultId: item?._id || item?.id,
      confidenceLevel: item?.confidenceLevel,
      note: item?.description,
      dateOfUpdate: new Date(item?.dateOfUpdate || new Date()),
      unit: item?.unit,
    });
  };

  const handleKeyResultAction = (type, id) => {
    if (type == "Delete") {
      if (window.confirm(`Do you really want to delete this key result`)) {
        ApiClient.delete(`okr?id=${id}`).then((res) => {
          if (res.success) {
            getKeyResults();
            getData({}, false);
            getOKRListing();
            if (detail?.id || detail?._id)
              detailModal(detail?.id || detail?._id, false);
          }
        });
      }
    } else if (type == "Child Objective") {
      getKeyResults(id?.okrId);
      setChildObjective(id);
      setform({
        ...form,
        id: "",
        parentId: id?._id || id?.id, // Pass only the raw ID value
        whyNow: "",
        contributors: "",
        ownerIds: "",
      });
      document.getElementById("CreateOKRModalOpen").click();
    } else if (type == "Child Key Result") {
      document.getElementById("OpenKeyResultModal").click();
      setchildKeyResult(id?._id || id?.id);
    } else {
      setkeyResult({
        title: "",
        unit: "",
        targetNumber: "",
        initialNumber: "",
        description: "",
        ownerIds: [],
        deadline: "no custom deadline",
        softDeadline: "",
        hardDeadline: "",
        session: null,
        ...id,
        ownerIds: id?.ownerDetails?.map((item) => {
          return { value: item?.id, label: item?.name };
        }),
        // softDeadline: new Date(id?.softDeadline),
        // hardDeadline: new Date(id?.hardDeadline),
      });
      document.getElementById("OpenKeyResultModal").click();
    }
  };

  const handleKeyModal = async (item) => {
    await detailModal(item?.id || item?._id, false);
    await setItem(item);
    document.getElementById(`OpenKeyResultModal`).click();
  };

  const delectOkr = (id) => {
    if (window.confirm(`Do you really want to delete this OKR`)) {
      let payload = {
        id: id,
      };
      loader(true);
      ApiClient.delete(`okr`, payload)
        .then((res) => {
          if (res?.success) {
            getOKRListing();
            getData();
            toast.success("OKR deleted successfully!"); // Display success message
          } else {
            toast.error("Failed to delete OKR."); // Display error message if deletion fails
          }
          loader(false);
        })
        .catch((error) => {
          console.error("Error deleting OKR:", error);
          toast.error("An error occurred while deleting the OKR."); // Display error message if an error occurs
          loader(false);
        });
    }
  };

  const detailModal = (ids, value = true) => {
    let id = ids || "";
    if (!layout) {
      return;
    }
    loader(true);
    ApiClient.get(`okr?id=${id}`).then((res) => {
      loader(false);
      if (res?.data?.id) {
        setDetail(res?.data);
        // setNewValue(updateKeyResult);
        setupdateKeyResult({
          ...updateKeyResult,
          okrId: res?.data?.id || res?.data?._id,
        });
        setnewupdated({
          ...updateKeyResult,
          okrId: res?.data?.id || res?.data?._id,
        });

        if (value && !childObjective) {
          document.getElementById("OpenFullSideScreenModal").click();
        }
        setChildObjective("");
        setform({ ...form, parentId: null, whyNow: "", contributors: null });
      }
    });
  };

  const findOKRProgress = (data) => {
    return methodModel.findOKRProgress(data);
  };

  const keyResultPercent = (item) => {
    return methodModel.keyResultPercent(item);
  };

  const clearOKRForm = () => {
    setDetail("");
    setChildObjective("");
    setform({
      ...form,
      id: "",
      ownerIds: "",
      title: "",
      keyType: "okr",
      parentId: null,
      whyNow: "",
      contributors: null,
    });
  };

  const resources =
    dataResources &&
    dataResources.map((item) => {
      return { id: item?._id, title: item?.title };
    });

  const handleFilter = () => {
    if (filter?.ownerId && filter?.ownerId?.length > 0) {
      let arr = filter?.ownerId || ownerID;
      getData({ page: 1, ownerId: arr.map((item) => item?.value).toString() });
    } else {
      getData({ page: 1, ownerId: "" });
    }
  };

  const handlechangesessions = (e) => {
    if (e) {
      dispatch(sessions(`${e.label}`));
      setSessionIdFilter(e);
      setfilter({ ...filter, sessionId: e });
      setalignfilter({ ...alignfilter, sessionId: e });
      navigate.push(`/sessions/okrs/${e.value}`);
      // localStorage.setItem("sessionID", e.value);
    }
  };

  const pageChange = (e) => {
    setfilter({ ...filter, page: e, count: 10 });
    getData({ page: e, count: filter.count });
  };

  const changePage = (e) => {
    // setfilter({ ...filter, page: e, count: 10 });
    setalignfilter({ ...alignfilter, page: e, count: 10 });
    getOKRListing({ page: e, count: filter.count });
  };

  const ConfidenceLevel = ({ data }) => {
    return (
      <>
        <div className="confidence-level text-end">
          <span
            className={`p-2 badges-color ${
              data?.confidenceLevel == "low" || data?.confidenceLevel == ""
                ? "red-color"
                : data?.confidenceLevel == "medium"
                ? "orange-color"
                : data?.confidenceLevel == "high"
                ? "green-color"
                : ""
            } text-capitalize`}
          >
            {data?.confidenceLevel == "low" ? (
              <span className="badges-diff">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  className="me-1"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                  <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
                </svg>
                {translate("offTrack")}{" "}
              </span>
            ) : data?.confidenceLevel == "medium" ? (
              <span className="badges-diff">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  className="me-1"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z"
                    fill="currentColor"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
                    fill="currentColor"
                  ></path>
                </svg>
                {translate("atRisk")}
              </span>
            ) : data?.confidenceLevel == "high" ? (
              <span className="badges-diff">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  className="me-1"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2.5a9.5 9.5 0 1 0 0 19 .75.75 0 0 1 0 1.5C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11a.75.75 0 0 1-1.5 0A9.5 9.5 0 0 0 12 2.5Z"></path>
                  <path d="m13.759 17.48 3.728 3.314a.308.308 0 0 0 .513-.23V18h4.25a.75.75 0 0 0 0-1.5H18v-2.564a.308.308 0 0 0-.513-.23L13.76 17.02a.308.308 0 0 0 0 .46ZM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
                </svg>
                {translate("onTrack")}
              </span>
            ) : (
              <span className="badges-diff">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  className="me-1"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                  <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
                </svg>
                OFF TRACK
              </span>
            )}
          </span>
        </div>
      </>
    );
  };

  const getConfidenceColor = (confidenceLevel) => {
    switch (confidenceLevel) {
      case "low":
        return "red-color";
      case "medium":
        return "orange-color";
      case "high":
        return "green-color";
      default:
        return "";
    }
  };

  const calculateProgress = (data) => {
    const { initialNumber, newValue, targetNumber } = data;
    const delta = targetNumber - initialNumber;
    const currentProgress = newValue - initialNumber;
    return `${currentProgress} of ${delta}`;
  };

  // Function to calculate delta
  const calculateDelta = (data) => {
    const { initialNumber, targetNumber } = data;
    return targetNumber - initialNumber;
  };

  // Function to calculate current progress
  const calculateCurrentProgress = (data) => {
    const { initialNumber, newValue } = data;
    return newValue - initialNumber;
  };

  const [valueData, setNewValue] = useState("");
  const [valueDataUnit, setNewValueUnit] = useState("");

  /*=================================start test datagrid ===============================*/
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [OKRFilter, setOKRFilter] = useState("keyResult");

  // Define the action dropdown function
  const renderActionDropdown = (item) => {
    const isChildObjective = item.keyType === "child_objective";
    const isKeyResult = item.keyType === "key_result";

    return (
      <div className="common-w actions">
        {user?.role !== "employee" && (
          <div className="dropsdowns_align spacing_equal text-end">
            <div className="dropdown d-flex justify-content-end">
              <p
                className="text-black pointer"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v text-dark new-ellipses"></i>
              </p>
              <div className="dropdown-menu menu-design">
                {/* Conditional rendering for dropdown options */}
                {!isKeyResult && (
                  <span
                    className="dropdown-item cursor-pointer"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleAction("Edit", item?._id || item?.id);
                    }}
                  >
                    <i className="fa fa-pen me-2"></i>
                    {translate("edit")}
                  </span>
                )}

                {/* Add Key Result only for Objectives */}
                {!isKeyResult && (
                  <span
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleKeyModal(item);
                    }}
                    data-toggle="modal"
                    data-target="#KeyResultModal"
                  >
                    <i className="fa fa-plus me-2"></i>
                    {translate("addKR")}
                  </span>
                )}

                {/* Add Child Objective Option for both Objectives and Child Objectives */}
                <span
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleKeyResultAction("Child Objective", item);
                  }}
                >
                  <i className="fa fa-plus me-2"></i>
                  {translate("addChild")}
                </span>

                {/* Delete option for all types */}
                <span
                  className="dropdown-item"
                  style={{
                    marginTop: "5px",
                    borderTop: "1px solid #e5e5e5",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const idToDelete = item?.id || item?._id;
                    delectOkr(idToDelete);
                  }}
                >
                  <i className="fa fa-trash me-2"></i>
                  {translate("delete")}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const toggleRow = (id) => {
    setExpandedRowIds((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const rows = data?.map((item, index) => {
    return {
      id: item._id || index,
      objectiveName: item.title,
      progress: item.progress || "N/A",
      keyResults: item.keyResults || "N/A",
      actions: item,
      childObjectives: item.childObjectives || [],
      owners: item.owners || [], // Fallback to an empty array if owners is undefined
    };
  });

  const ObjectivesList = ({ data }) => {
    return (
      <div className="container">
        {data.map((item) => {
          const isExpanded = expandedRowIds.includes(item.id); // Check if this specific row is expanded
          return (
            <ObjectiveCard
              key={item.id}
              item={item}
              isExpanded={isExpanded}
              toggleRow={toggleRow}
            />
          );
        })}
      </div>
    );
  };

  const ObjectiveCard = ({ item }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const hasKeyResults = item?.keyResults?.length > 0;

    const toggleRow = () => {
      setIsExpanded(!isExpanded);
    };

    return (
      <div className="card mb-1" key={item?._id || item?.id}>
        <div className="card-body">
          <div className="objective-section">
            <div className="d-flex justify-content-between align-items-center">
              {/* Arrow Section */}
              <div
                className="d-flex align-items-center"
                style={{ width: "50px", justifyContent: "center" }}
              >
                {hasKeyResults && (
                  <div onClick={toggleRow} style={{ cursor: "pointer" }}>
                    {isExpanded ? <FaArrowDown /> : <FaArrowRight />}
                  </div>
                )}
              </div>
              {/* Icon Section */}
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "center", marginRight: "10px" }}
              >
                <span className="key-img">
                  <Tooltip title={translate("objective")} placement="top">
                    <span className="mr-1 objective-img">
                      <LuCircleDotDashed />
                    </span>
                  </Tooltip>
                </span>
              </div>
              {/* Progress Display Section */}
              <div
                className="d-flex align-items-center"
                style={{ marginRight: "10px" }}
              >
                <div className="paddingleft2">
                  <Tooltip
                    title={translate("objectiveProgress")}
                    placement="top"
                  >
                    <span className={`progressDiv progress-color`}>
                      {findOKRProgress(
                        item?.keyResults,
                        "this okr is from okr listing"
                      )}
                    </span>
                  </Tooltip>
                </div>
              </div>

              {/* Title Section */}
              <div className="fs14 flex-grow-1" style={{ flexBasis: "50%" }}>
                <h5 className="mb-0 fs-5">
                  <a
                    href="#"
                    className="fs-5 pointer d-flex mb-0 "
                    onClick={(e) => {
                      detailModal(item?._id ? item._id : item.id); // Call the modal function with the ID
                    }}
                  >
                    <span className="obj-titles font-weight-bold fs14">
                      {item?.title
                        ? item.title.charAt(0).toUpperCase() +
                          item.title.slice(1)
                        : ""}
                    </span>
                  </a>
                </h5>
              </div>
            </div>

            {/* Actions Section (Center between rows) */}
            <div
              className="d-flex justify-content-end align-items-center"
              style={{
                justifyContent: "flex-end",
                alignSelf: "center",
                marginLeft: "auto",
                height: "10px",
                //marginBottom: "10px",
              }}
            >
              {renderActionDropdown(item)}
            </div>

            {/* Progress bar and Owner Section */}
            <div className="row align-items-center">
              {/* Progress Bar Section for Average Progress */}
              <div className="col-5">
                <Tooltip
                  title={`Objective Progress: ${findOKRProgress(
                    item?.keyResults
                  )}`}
                  placement="top"
                >
                  <div
                    className="progress"
                    style={{
                      height: "15px",
                      marginTop: "5px",
                      marginLeft: "70px",
                      maxWidth: "600px",
                    }}
                  >
                    <div
                      className="progress-bar progress-bar-color"
                      role="progressbar"
                      style={{
                        width: findOKRProgress(item?.keyResults),
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </Tooltip>
              </div>

              <div className="col-2 ml-5">
                {/* Empty spacer for Confidence */}
              </div>

              {/* Owner Section */}
              <div className="col-1 ml-4 text-center d-flex align-items-center">
                <div className="ml-2 owners-number owner-no-main">
                  {item.ownerDetails && item.ownerDetails.length > 0 ? (
                    item.ownerDetails.some((owner) => owner.id) ? (
                      item.ownerDetails.slice(0, 4).map((owner, index) =>
                        owner.id ? (
                          <React.Fragment key={owner.id || index}>
                            <Tooltip title={owner.name} placement="top">
                              <img
                                src={methodModel.userImg(owner.image)}
                                alt={owner.name}
                                className="rounded-circle"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  objectFit: "cover",
                                  marginRight: "5px",
                                }}
                              />
                            </Tooltip>
                          </React.Fragment>
                        ) : null
                      )
                    ) : (
                      <Tooltip title="No owner" placement="top">
                        <div
                          style={{
                            position: "relative",
                            width: "30px",
                            height: "30px",
                            marginRight: "5px",
                          }}
                        >
                          <img
                            src="/assets/img/noowner.png"
                            alt="No Owner"
                            className="rounded-circle"
                            style={{
                              width: "30px",
                              height: "30px",
                              objectFit: "cover",
                            }}
                          />
                          <div
                            style={
                              {
                                // position: "absolute",
                                // top: "50%",
                                // left: "50%",
                                // width: "30px",
                                // height: "2px",
                                // backgroundColor: "#eb5146",
                                // transform: "translate(-50%, -50%) rotate(45deg)",
                              }
                            }
                          />
                        </div>
                      </Tooltip>
                    )
                  ) : (
                    <span>No Owners</span>
                  )}
                  {item.ownerDetails && item.ownerDetails.length > 4 && (
                    <small className="pt-1">+more</small>
                  )}
                </div>
              </div>

              {/* Contributor Section */}
              <div className="col-2  ml-5 text-center d-flex align-items-center">
                <div className="ml-2 owners-number owner-no-main">
                  {item.contributorDetails &&
                  item.contributorDetails.length > 0 ? (
                    item.contributorDetails.some(
                      (contributor) => contributor.id
                    ) ? (
                      item.contributorDetails
                        .slice(0, 4)
                        .map((contributor, index) =>
                          contributor.id ? (
                            <React.Fragment key={contributor.id || index}>
                              <Tooltip title={contributor.name} placement="top">
                                <img
                                  src={methodModel.userImg(contributor.image)}
                                  alt={contributor.name}
                                  className="rounded-circle"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    objectFit: "cover",
                                    marginRight: "5px",
                                  }}
                                />
                              </Tooltip>
                            </React.Fragment>
                          ) : null
                        )
                    ) : (
                      <Tooltip title="No contributor" placement="top">
                        <div
                          style={{
                            position: "relative",
                            width: "30px",
                            height: "30px",
                            marginRight: "5px",
                          }}
                        >
                          <img
                            src="/assets/img/noowner.png"
                            alt="No Owner"
                            className="rounded-circle"
                            style={{
                              width: "30px",
                              height: "30px",
                              objectFit: "cover",
                            }}
                          />
                          <div
                            style={
                              {
                                // position: "absolute",
                                // top: "50%",
                                // left: "50%",
                                // width: "30px",
                                // height: "2px",
                                // backgroundColor: "#eb5146",
                                // transform: "translate(-50%, -50%) rotate(45deg)",
                              }
                            }
                          />
                        </div>
                      </Tooltip>
                    )
                  ) : (
                    <span>No Owners</span>
                  )}
                  {item.ownerDetails && item.ownerDetails.length > 4 && (
                    <small className="pt-1">+more</small>
                  )}
                </div>
              </div>

              <div className="col-1">{/* Empty spacer for Action */}</div>
            </div>
          </div>
          {/* Conditionally Render Separator Bar */}
          {isExpanded && <div className="separator-bar"></div>}
        </div>

        {/* Key Results Section */}
        {isExpanded && item?.keyResults?.length > 0 && (
          <div className="transition">
            {item.keyResults.map((data, index) => (
              <div className="key-result-section">
                <div
                  className="key-result-main"
                  key={data._id || index}
                  style={{
                    padding: "10px 0",
                    marginLeft: "90px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ marginLeft: "15px" }}
                  >
                    {/* Icon Section */}
                    <div
                      className="d-flex align-items-center"
                      style={{ justifyContent: "center" }}
                    >
                      <span className="key-img">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path fill="none" d="M0 0h24v24H0V0z"></path>
                          <path d="M17 4h3v16h-3V4zM5 14h3v6H5v-6zm6-5h3v11h-3V9z"></path>
                        </svg>
                      </span>
                    </div>
                    <span
                      className={`progressDiv mx-2 ${getConfidenceColor(
                        data?.confidenceLevel
                      )}`}
                    >
                      {keyResultPercent(data)}%
                    </span>

                    {/* Title section */}
                    <div className="fs14 flex-grow-1">
                      <h5 className="mb-0 fs-5">
                        {/* <a
                            href="#"
                            className="fs-5 pointer d-flex mb-0 "
                            onClick={(e) => {
                              detailModal(item?._id ? item._id : item.id); // Call the modal function with the ID
                            }}
                          > */}
                        <span className="obj-titles  fs14">{data?.title}</span>
                        {/* </a> */}
                      </h5>
                      {/* <span className="fw-600">{data?.title}</span> */}
                    </div>

                    {/* Actions Section */}
                    <div
                      className="d-flex justify-content-end align-items-center"
                      style={{
                        justifyContent: "flex-end",
                        alignSelf: "center",
                        marginRight: "15px",
                        height: "10px",
                        marginTop: "5px",
                      }}
                    >
                      {/* {user?.role !== "employee" && ( */}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {renderActionDropdown(data)}{" "}
                        {/* Pass the key result item */}
                      </div>
                      {/* )} */}
                    </div>
                  </div>

                  <div className="row align-items-center mt-1 ">
                    {/* Combined section for Calculated Progress and Progress Bar */}
                    <div
                      className="col-5 d-flex flex-row"
                      style={{
                        alignItems: "center",
                        marginLeft: "15px",
                      }}
                    >
                      {/* Calculated Progress */}
                      <div
                        style={{
                          fontSize: "1rem",
                          color: "rgba(0, 0, 0, 0.6)",
                          marginRight: "5px",
                        }}
                      >
                        {calculateCurrentProgress(data)}
                      </div>
                      {/* Progress bar section */}
                      <Tooltip
                        title={`Key result Progress: ${keyResultPercent(
                          data
                        )}%`}
                        placement="top"
                      >
                        <div
                          className="progress"
                          style={{
                            maxWidth: "300px",
                            height: "15px",
                            flexGrow: 1,
                          }}
                        >
                          <span className="percentage">
                            {keyResultPercent(data)}%
                          </span>
                          {/* Progress Bar */}
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${keyResultPercent(data)}%`,
                            }}
                            aria-valuenow={keyResultPercent(data)}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          />
                        </div>
                      </Tooltip>
                      {/* Calculated Progress */}
                      <div
                        style={{
                          fontSize: "1rem",
                          color: "rgba(0, 0, 0, 0.6)",
                          marginLeft: "5px",
                        }}
                      >
                        {calculateDelta(data)}
                      </div>
                    </div>
                    {/* Confidence Level Badge */}
                    <div className="col-2 mr-2">
                      <div className="d-flex align-items-center">
                        {data?.confidenceLevel && (
                          <div>
                            <ConfidenceLevel data={data} />
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Owner section */}
                    <div className="col-2 ml-1 text-center d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <Tooltip title={data.krOwners[0]?.name} placement="top">
                          <div
                            className="ml-2 owners-number owner-no-main"
                            style={{ marginLeft: "-35px" }}
                          >
                            {data.krOwners &&
                            data.krOwners.length > 0 &&
                            data.krOwners[0].id !== null ? (
                              <img
                                src={methodModel.userImg(
                                  data.krOwners[0].image
                                )}
                                alt={data.krOwners[0].name}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <Tooltip title="No owner" placement="top">
                                <div
                                  style={{
                                    position: "relative",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                >
                                  <img
                                    src="/assets/img/noowner.png"
                                    alt="No Owner"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <div
                                    style={
                                      {
                                        // position: "absolute",
                                        // top: "50%",
                                        // left: "50%",
                                        // width: "30px",
                                        // height: "2px",
                                        // backgroundColor: "#eb5146",
                                        // transform:
                                        //   "translate(-50%, -50%) rotate(45deg)",
                                      }
                                    }
                                  />
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  /*=================================end test datagrid ===============================*/

  return (
    <>
      <I18nProvider locale={locale}>
        <div className="inners_wrapper ">
          {data?.length >= 0 || filter?.ownerId ? (
            <>
              <div className="d-flex align-items-center switchtab">
                {/* <Tooltip placement="right" title="Back">
                <span
                  className="backarrow"
                  onClick={(e) => {
                    navigate.push(`/sessions`);
                    dispatch(sessions(""));
                  }}
                >
                  <a className="aclass">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      data-icon="goal"
                      aria-hidden="true"
                      viewBox="0 0 448 512"
                      title="back"
                    >
                      <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>
                  </a>
                </span>
              </Tooltip> */}
                <ul
                  className="nav nav-pills okrs_tabsul"
                  id="pills-tab"
                  role="tablist"
                >
                  <li
                    className="nav-item obj-btn w-50 top-btn"
                    role="presentation"
                  >
                    <button
                      className="nav-link active"
                      id="pills-objective-tab"
                      data-toggle="pill"
                      data-target="#pills-objective"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                      style={{ borderRadius: "8px 0 0 0" }}
                    >
                      <span className="mr-2">
                        <MdDataObject />
                      </span>
                      {translate("objectives")}
                    </button>
                  </li>
                  <li
                    className="nav-item alignment-button w-50"
                    role="presentation"
                  >
                    <button
                      className="nav-link"
                      id="pills-allignment-tab"
                      data-toggle="pill"
                      data-target="#pills-allignment"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                      style={{ borderRadius: "0 8px 0 0" }}
                      // onClick={() => getOKRListing({page:filter.page})}
                    >
                      <span className="mr-2">
                        <CiAlignRight />
                      </span>{" "}
                      {translate("alignment")}
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tbs_data_okrs">
                <div className="okrs_objec_inners mt-3">
                  {layout && (
                    <div className="d-flex align-items-center justify-content-between headertop">
                      <div className="text-right ">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => clearOKRForm()}
                            id="OpenCreateOKRModal"
                            data-toggle="modal"
                            data-target="#OKRsModal"
                          >
                            {translate("createOKR")}
                          </button>
                          {/* <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="visually-hidden">
                                Toggle Dropdown
                              </span>
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <button
                                  type="button"
                                  className="dropdown-item fa fa-star"
                                  id="OpenCreateAiOKRModal"
                                  data-toggle="modal"
                                  data-target="#OKRsAIModal"
                                >
                                  {translate("createAiOKR")}
                                </button>
                              </li>
                            </ul> */}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-objective"
                      role="tabpanel"
                      aria-labelledby="pills-objective-tab"
                    >
                      <div className="objective_tables">
                        <div className="objective-card">
                          <div className="dropdown filter-dropdown ms-3">
                            <Menu>
                              <Menu.Button
                                className="btn border-btn d-flex align-items-center"
                                onClick={(e) =>
                                  setSessionIdFilter({
                                    ...SessionIdFilter,
                                    label: sessionss?.data,
                                    value: id,
                                  })
                                }
                              >
                                <CiFilter className="me-2" />
                                {translate("filter")}
                                <IoIosArrowDown className="ms-2" />
                              </Menu.Button>

                              <Menu.Items>
                                <div
                                  className="dropdown-menu d-block"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  {/* Session Filter */}
                                  <a
                                    className="dropdown-item"
                                    onChange={(e) => e.stopPropagation()}
                                  >
                                    <div className="d-flex align-items-center">
                                      <label>{translate("session")}</label>
                                      <div
                                        className="ms-2 w-100"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        {LoadingState ? null : (
                                          <Select
                                            value={SessionIdFilter}
                                            isClearable={true}
                                            onChange={(e) =>
                                              handlechangesessions(e)
                                            }
                                            options={session}
                                            className="basic-multi-select text-capitalize"
                                            placeholder="Select Owners"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </a>

                                  {/* Objective Owners Filter */}
                                  {user?.role !== "employee" && (
                                    <a
                                      className="dropdown-item mb-3"
                                      onChange={(e) => e.stopPropagation()}
                                    >
                                      <div className="d-flex align-items-center">
                                        <label>{translate("owner")}</label>
                                        <div
                                          className="ms-2 w-100"
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <Select
                                            value={filter?.ownerId}
                                            isClearable={true}
                                            onChange={(e) => {
                                              setfilter({
                                                ...filter,
                                                ownerId: e,
                                              });
                                              localStorage.setItem(
                                                "ownerId",
                                                JSON.stringify(e)
                                              );
                                            }}
                                            options={owners}
                                            className="basic-multi-select text-capitalize"
                                            placeholder="Select Owners"
                                            isMulti
                                          />
                                        </div>
                                      </div>
                                    </a>
                                  )}

                                  {/* KR Owner Filter */}
                                  {/*
                              {user?.role !== "employee" && (
                                <a className="dropdown-item mb-3" onChange={(e) => e.stopPropagation()}>
                                <div className="d-flex align-items-center">
                                  <label>Key Result Owner</label>
                                  <div className="ms-2 w-100" onClick={(e) => e.stopPropagation()}>
                                    <Select
                                      value={filter?.krOwnerId}
                                      isClearable={true}
                                      onChange={(e) => {
                                        console.log("Selected KR Owner:", e); // Log for verification
                                        setfilter({ ...filter, krOwnerId: e });
                                        localStorage.setItem("krOwnerId", JSON.stringify(e));
                                      }}
                                      options={owners} // Use actual KR owners options if different
                                      className="basic-multi-select text-capitalize"
                                      placeholder="Select KR Owner"
                                      isMulti
                                    />
                                  </div>
                                </div>
                              </a>
                              )}
                              */}
                                  {/* Contributor Filter */}
                                  {/*
                              {user?.role !== "employee" && (
                                <a className="dropdown-item mb-3" onChange={(e) => e.stopPropagation()}>
                                  <div className="d-flex align-items-center">
                                    <label>Contributor</label>
                                    <div className="ms-2 w-100" onClick={(e) => e.stopPropagation()}>
                                      <Select
                                        value={filter?.contributorId}  // Placeholder constant
                                        isClearable={true}
                                        onChange={(e) => {
                                          setfilter({ ...filter, contributorId: e });
                                          localStorage.setItem("contributorId", JSON.stringify(e)); // Placeholder constant
                                        }}
                                        options={owners}  // Replace with actual contributors options if different
                                        className="basic-multi-select text-capitalize"
                                        placeholder="Select Contributor"
                                        isMulti
                                      />
                                    </div>
                                  </div>
                                </a>
                              )}
                          */}
                                  {/* Status Filter */}
                                  {/*
                              <a className="dropdown-item mb-3" onChange={(e) => e.stopPropagation()}>
                                <div className="d-flex align-items-center">
                                  <label>Status</label>
                                  <div className="ms-2 w-100" onClick={(e) => e.stopPropagation()}>
                                    <Select
                                      value={filter?.status}  // Placeholder constant
                                      isClearable={true}
                                      onChange={(e) => {
                                        setfilter({ ...filter, status: e });
                                        localStorage.setItem("status", JSON.stringify(e)); // Placeholder constant
                                      }}
                                      options={[]}  // Replace with actual status options
                                      className="basic-multi-select text-capitalize"
                                      placeholder="Select Status"
                                    />
                                  </div>
                                </div>
                              </a>
                              */}
                                  {/* Filter and Clear Buttons */}
                                  <div className="clear-btn me-3 mb-2 d-flex align-items-center justify-content-end">
                                    {(filter?.search ||
                                      filter?.ownerId ||
                                      filter?.krOwnerId ||
                                      filter?.contributorId ||
                                      filter?.status) && (
                                      <>
                                        <button
                                          className="btn btn-primary me-2 filter-btn d-flex align-items-center"
                                          onClick={(e) => handleFilter()}
                                        >
                                          Filter
                                        </button>
                                        <button
                                          className="btn btn-secondary filter-btn d-flex align-items-center"
                                          onClick={(e) => {
                                            setfilter({
                                              ...filter,
                                              page: 1,
                                              search: "",
                                              ownerId: "",
                                              krOwnerId: "", // Placeholder constant
                                              contributorId: "", // Placeholder constant
                                              status: "", // Placeholder constant
                                            });
                                            getData({
                                              page: 1,
                                              search: "",
                                              ownerId: "",
                                              krOwnerId: "", // Placeholder constant
                                              contributorId: "", // Placeholder constant
                                              status: "", // Placeholder constant
                                            });
                                            localStorage.removeItem("ownerId");
                                            localStorage.removeItem(
                                              "krOwnerId"
                                            ); // Placeholder constant
                                            localStorage.removeItem(
                                              "contributorId"
                                            ); // Placeholder constant
                                            localStorage.removeItem("status"); // Placeholder constant
                                          }}
                                        >
                                          <RxCross2 className="me-2" />
                                          Clear
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </Menu.Items>
                            </Menu>
                          </div>

                          <div className="card_line_head_two  align-items-center align-width">
                            <div className="col-5 ml-5">
                              <h6 className="mb-0">{translate("progress")}</h6>
                            </div>
                            <div className="col-2 ">
                              <h6 className="mb-0">
                                {translate("confidence")}
                              </h6>
                            </div>
                            <div className="col-1 mr-4">
                              <h6 className="mb-0">{translate("owner")}</h6>
                            </div>
                            <div className="col-2">
                              <h6 className="mb-0">
                                {translate("contributor")}
                              </h6>
                            </div>
                            <div className="col-1">
                              <h6 className="mb-0 pe-2">
                                {translate("action")}
                              </h6>
                            </div>
                          </div>

                          {!loading ? (
                            <div className="mvp_table objective-tbl-main  obj-tbl">
                              <div style={{ height: "100%", width: "100%" }}>
                                {/* Render cards progressively */}
                                {data.map((item) => {
                                  const isExpanded = expandedRowIds.includes(
                                    item.id
                                  );
                                  return (
                                    <ObjectiveCard
                                      key={item.id}
                                      item={item}
                                      isExpanded={isExpanded}
                                      toggleRow={toggleRow}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>

                        {!loading && data?.length <= 0 && (
                          <div className="py-3 text-center no-data">
                            <img src="/assets/img/no-data-logo.svg" />
                            No Data Found
                          </div>
                        )}
                        {OKRListing?.length != 0 && (
                          <div className="paginationWrapper">
                            <span>
                              {translate("show")}{" "}
                              {filter.page === Math.ceil(total / filter.count)
                                ? total
                                : filter.page * filter.count < total
                                ? filter.page * filter.count
                                : total}{" "}
                              {translate("of")} {total} {translate("records")}
                            </span>
                            <Pagination
                              currentPage={filter.page}
                              totalSize={total}
                              sizePerPage={filter.count}
                              changeCurrentPage={pageChange}
                            />
                          </div>
                        )}
                        {loading ? (
                          <div className="d-flex justify-content-center py-4">
                            <img
                              src="/assets/img/loader.gif"
                              className="text-center"
                              width="40px"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-allignment"
                      role="tabpanel"
                      aria-labelledby="pills-allignment-tab"
                    >
                      {layout ? (
                        <>
                          <AlignmentOKR
                            valueData={valueData}
                            setNewValue={setNewValue}
                            valueDataUnit={valueDataUnit}
                            setNewValueUnit={setNewValueUnit}
                            handleKeyResultAction={handleKeyResultAction}
                            handleAction={handleAction}
                            handleUpdateKeyResultModal={
                              handleUpdateKeyResultModal
                            }
                            id={id}
                            ConfidenceLevel={ConfidenceLevel}
                            findOKRProgress={findOKRProgress}
                            updateKeyResult={updateKeyResult}
                            seteditor={seteditor}
                            editor={editor}
                            keyResult={keyResult}
                            setkeyResult={setkeyResult}
                            setupdateKeyResult={setupdateKeyResult}
                            clearFields={clearFields}
                            getData={getData}
                            OKRListing={OKRListing}
                            setOKRListing={setOKRListing}
                            getOKRListing={getOKRListing}
                            data={data}
                            owners={owners}
                            getKeyResults={getKeyResults}
                            detail={detail}
                            detailModal={detailModal}
                            filter={alignfilter}
                            total={total}
                            changePage={changePage}
                            view={(item) => {
                              if (item?.keyType == "key_result") return;
                              if (item?.keyType == "child_objective") {
                                detailModal(item?._id ? item._id : item.id);
                              } else {
                                detailModal(item?._id ? item._id : item.id);
                              }
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <Tooltip placement="right" title="Back">
                <span
                  className="backarrow"
                  onClick={(e) => {
                    navigate.push(`/sessions`);
                  }}
                >
                  <a className="aclass">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      data-icon="goal"
                      aria-hidden="true"
                      viewBox="0 0 448 512"
                      title="back"
                    >
                      <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>
                  </a>
                </span>
              </Tooltip>
              {/* <div className="success-story">
              <div className="text-center">
                <p className="text-center">
                  <h3>Start Your Success Story Now</h3>
                </p>
              </div>
              <div className="create_okrs">
              <button
                        className="btn btn-primary"
                        onClick={(e) => clearOKRForm()}
                        id="OpenCreateOKRModal"
                        data-toggle="modal"
                        data-target="#OKRsModal"
                      >
                        Create OKRs
                      </button>
              </div>
            </div> */}
            </>
          )}

          {layout ? (
            <>
              <ModalOKR
                sessionId={id}
                employeeData={employeeData}
                valueData={valueData}
                setNewValue={setNewValue}
                valueDataUnit={valueDataUnit}
                setNewValueUnit={setNewValueUnit}
                getOKRListing={getOKRListing}
                detail={detail}
                handleUpdateKeyResultModal={handleUpdateKeyResultModal}
                handleAction={handleAction}
                handleKeyResultAction={handleKeyResultAction}
                findOKRProgress={findOKRProgress}
                keyResult={keyResult}
                setkeyResult={setkeyResult}
                seteditor={seteditor}
                editor={editor}
                owners={owners}
                updateKeyResult={updateKeyResult}
                newupdated={newupdated}
                setupdateKeyResult={setupdateKeyResult}
                clearFields={clearFields}
                childObjective={childObjective}
                form={form}
                setform={setform}
                getData={getData}
                sessions={session}
                getKeyResults={getKeyResults}
                parentKeyResult={parentKeyResult}
                total={total}
                keyResultData={keyResultData}
                setChildObjective={setChildObjective}
                setkeyResultData={setkeyResultData}
                setparentKeyResult={setparentKeyResult}
                detailModal={detailModal}
                childKeyResult={childKeyResult}
                setchildKeyResult={setchildKeyResult}
                result={(e) => {
                  if (e.event == "Parent Submit" || e.event == "Child Submit") {
                    detailModal(e.value?._id ? e.value?._id : e.value?.id);
                  }
                }}
              />
            </>
          ) : (
            <></>
          )}

          {layout ? (
            <>
              <ChildModalOKR
                valueData={valueData}
                setNewValue={setNewValue}
                valueDataUnit={valueDataUnit}
                setNewValueUnit={setNewValueUnit}
                detail={detail}
                handleAction={handleAction}
                findOKRProgress={findOKRProgress}
                keyResult={keyResult}
                setkeyResult={setkeyResult}
                seteditor={seteditor}
                editor={editor}
                owners={owners}
                updateKeyResult={updateKeyResult}
                newupdated={newupdated}
                setupdateKeyResult={setupdateKeyResult}
                clearFields={clearFields}
                childObjective={childObjective}
                form={form}
                setform={setform}
                getData={getData}
                sessions={session}
                getKeyResults={getKeyResults}
                parentKeyResult={parentKeyResult}
                total={total}
                keyResultData={keyResultData}
                setChildObjective={setChildObjective}
                setkeyResultData={setkeyResultData}
                setparentKeyResult={setparentKeyResult}
                detailModal={detailModal}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </I18nProvider>
    </>
  );
};

export default OKRindex;
